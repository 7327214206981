<template>
  <div>
    <Navbar @logout="logout"></Navbar>
    <router-view :userUid="userUid" :userEmail="userEmail" v-if="userUid"></router-view>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/assets/firebase';
import Navbar from '@/components/Navbar.vue';

// auth 初始化
const auth = getAuth();

export default {
  data() {
    return {
      userUid: '',
      userEmail: '',
    };
  },
  methods: {
    async checkNewUser() {
      const loader = this.$loading.show();
      const docRef = doc(db, 'users', this.userUid);
      const docSnap = await getDoc(docRef);
      // 如果是新用戶就建立資料
      if (!docSnap.exists()) {
        loader.hide();
        const now = Date.now();
        setDoc(docRef, {
          name: auth.currentUser.displayName,
          email: auth.currentUser.email,
          createdAt: now,
          updatedAt: now,
        });
      } else {
        loader.hide();
      }
    },
    logout() {
      this.userUid = '';
      signOut(auth)
        .then(() => {
          // 登出成功
        })
        .catch((error) => {
          // 登出失敗
          this.$swal({
            text: String(error),
            icon: 'error',
          });
        });
    },
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.userUid = user.uid;
        this.userEmail = user.email;
        this.checkNewUser();
      } else {
        this.$router.push('/login');
      }
    });
  },
  components: {
    Navbar,
  },
};
</script>
