<template>
  <div>
    <div class="header container-fluid">
      <div class="container p-0">
        <nav class="navbar navbar-expand-lg py-0">
          <div class="container-fluid d-flex justify-content-round">
            <div class="nav-block d-flex flex-row">
              <router-link to="/surveys" v-slot="{ isActive, href }">
                <a :href="href" :class="{ 'is-active': isActive }" class="nav-link">
                  <font-awesome-icon :icon="['fas', 'pencil-ruler']" class="nav-icon" />
                  <span>量表填寫</span>
                  <span class="sr-only" v-if="isActive">(current)</span>
                </a>
              </router-link>
              <router-link to="/projects" v-slot="{ isActive, href }">
                <a :href="href" :class="{ 'is-active': isActive }" class="nav-link">
                  <font-awesome-icon :icon="['fas', 'clinic-medical']" class="nav-icon" />
                  <span>計畫管理</span>
                  <span class="sr-only" v-if="isActive">(current)</span>
                </a>
              </router-link>
              <!-- <router-link to="/cases" v-slot="{ isActive, href }">
                <a :href="href" :class="{ 'is-active': isActive }" class="nav-link">
                  <font-awesome-icon :icon="['fas', 'users']" class="nav-icon" />
                  <span>個案管理</span>
                  <span class="sr-only" v-if="isActive">(current)</span>
                </a>
              </router-link> -->
              <router-link to="/results" v-slot="{ isActive, href }">
                <a :href="href" :class="{ 'is-active': isActive }" class="nav-link">
                  <font-awesome-icon :icon="['fas', 'chart-bar']" class="nav-icon" />
                  <span>收案管理</span>
                  <span class="sr-only" v-if="isActive">(current)</span>
                </a>
              </router-link>
              <router-link to="/setting" v-slot="{ isActive, href }">
                <a :href="href" :class="{ 'is-active': isActive }" class="nav-link">
                  <font-awesome-icon :icon="['fas', 'cog']" class="nav-icon" />
                  <span>個人設定</span>
                  <span class="sr-only" v-if="isActive">(current)</span>
                </a>
              </router-link>
            </div>
            <div class="nav-block d-flex flex-row">
              <a href="#" class="nav-link" @click.prevent="logout">
                <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="nav-icon" />
                <span>登出</span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$emit('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: $primary;
  nav {
    height: 3.5rem;
  }
  a {
    text-decoration: none;
  }
  .nav-block {
    .nav-link {
      color: white;
      font-size: 1rem;
      border-radius: 0.5rem;
      margin-right: 0.5rem;
      cursor: pointer;
      .nav-icon {
        color: white;
        font-size: 1rem;
        margin-bottom: -5px;
        margin: 0 0.5rem 0 0;
      }
      &:hover {
        background-color: white;
        text-decoration: none;
        color: $primary;
        .nav-icon {
          color: $primary;
        }
      }
    }
    .is-active {
      background-color: white;
      color: $primary;
      .nav-icon {
        color: $primary;
      }
    }
  }
}
</style>
